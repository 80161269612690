import React, { useState } from 'react'
import { Link, Outlet} from "react-router-dom";
import { CopyToClipboardButton } from '../CopyToClipboardButton';

const data = [
    {text:'Dragon Professional Individual 15 French Downlod',url:'https://thedragonhelp.us/azds/Dragon_Professional_Individual_15.61.200.010-French.exe'},
    {text:'EM-S61A-47000 DPI for Mac EN 6.0 Downlod',url:'https://thedragonhelp.us/azds/EM-S61A-47000_DPI_for_Mac_EN_6.0.dmg'},
    {text:'dragon legal Downlod',url:'https://thedragonhelp.us/azds/dragon-legal.exe'},
    {text:'DPI 15.61.200.010 German Downlod',url:'https://thedragonhelp.us/azds/DPI_15.61.200.010_German.exe'},
    {text:'DNS Home English v15 Downlod',url:'https://thedragonhelp.us/azds/DNS_Home_English_v15.exe'},
    {text:'DPI 15.61.200.010 English Downlod',url:'https://thedragonhelp.us/azds/DPI_15.61.200.010_English.exe'},
    {text:'Dragon Professional Individual 15.61.200.010 Spanish Downlod',url:'https://thedragonhelp.us/azds/Dragon_Professional_Individual_15.61.200.010-Spanish.exe'},
    {text:'15.50.200.030 DMPE_4.3 FullClient ENU Downlod',url:'https://thedragonhelp.us/azds/15.50.200.030_DMPE_4.3_FullClient_ENU.exe'},
    {text:'DNS Home English v15 DLM Downlod',url:'https://thedragonhelp.us/azds/DNS_Home_English_v15-DLM.exe'},
    {text:'Dragon Professional Individual 15.61.200.010 English DLM Downlod',url:'https://thedragonhelp.us/azds/Dragon_Professional_Individual_15.61.200.010-English_DLM (1).exe'},
    {text:'Dragon Professional Individual 15.61.200.010 French DLM Downlod',url:'https://thedragonhelp.us/azds/Dragon_Professional_Individual_15.61.200.010-French_DLM.exe'},
    {text:'EM-K81A-48070 DLM Downlod',url:'https://thedragonhelp.us/azds/EM-K81A-48070_DLM (2).exe'},
    {text:'Dragon Professional 16 English DLM Downlod',url:'https://thedragonhelp.us/azds/DragonProfessional16English-DLM.exe'},
    {text:'Dragon Legal 16 English DLM Downlod',url:'https://thedragonhelp.us/azds/DragonLegal16English-DLM.exe'},
    {text:'Dragon professional English 16 Downlod',url:'https://thedragonhelp.us/azds/Dragon_professional_English_16.zip'},
    {text:'Dragon Professional16French-DLM Downlod',url:'https://thedragonhelp.us/azds/DragonProfessional16French-DLM (1).exe.zip'},
    {text:'Dragon Medical Practice Edition 4.3 Downlod',url:'https://thedragonhelp.us/azds/Dragon_Medical_Practice_Edition_4.3.zip'},
]
export const Admin = () => {
    
    return (
        <>
        <div >
            <Link to='/Deactive'>Deactive</Link>
            {data?.map((item,index)=>{
                return(
                    <div style={{display:'flex', alignItems:'center', gap: 20}}>
                    <h2>{index + 1}</h2>
                    <h1>{item?.text}</h1>
                    <CopyToClipboardButton textToCopy={item?.url} />
                    </div>
                )
            })}
            {/* <div > <a href="https://thedragonhelp.us/azds/Dragon_Professional_Individual_15.61.200.010-French.exe" download><h2>Dragon Professional Individual 15 French Downlod</h2> </a> <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/EM-S61A-47000_DPI_for_Mac_EN_6.0.dmg" download><h2>EM-S61A-47000 DPI for Mac EN 6.0 Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/dragon-legal.exe" download><h2>dragon legal Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/DPI_15.61.200.010_German.exe" download><h2>DPI 15.61.200.010 German Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/DNS_Home_English_v15.exe" download><h2>DNS Home English v15 Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/DPI_15.61.200.010_English.exe" download><h2>DPI 15.61.200.010 English Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/Dragon_Professional_Individual_15.61.200.010-Spanish.exe" download><h2>Dragon Professional Individual 15.61.200.010 Spanish Downlod</h2> </a> <CopyToClipboardButton textToCopy={'linkToCopy'} />  </div>
            <div > <a href="https://thedragonhelp.us/azds/15.50.200.030_DMPE_4.3_FullClient_ENU.exe" download><h2>15.50.200.030 DMPE_4.3 FullClient ENU Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/DNS_Home_English_v15-DLM.exe" download><h2>DNS Home English v15 DLM Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/Dragon_Professional_Individual_15.61.200.010-English_DLM (1).exe" download><h2>Dragon Professional Individual 15.61.200.010 English DLM Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/Dragon_Professional_Individual_15.61.200.010-French_DLM.exe" download><h2>Dragon Professional Individual 15.61.200.010 French DLM Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/EM-K81A-48070_DLM (2).exe" download><h2>EM-K81A-48070 DLM Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/DragonProfessional16English-DLM.exe" download><h2>Dragon Professional 16 English DLM Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/DragonLegal16English-DLM.exe" download><h2>Dragon Legal 16 English DLM Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/Dragon_professional_English_16.zip" download><h2>Dragon professional English 16 Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/DragonProfessional16French-DLM (1).exe.zip" download><h2>Dragon Professional16French-DLM (1) Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div>
            <div > <a href="https://thedragonhelp.us/azds/Dragon_Medical_Practice_Edition_4.3.zip" download><h2>Dragon Medical Practice Edition 4.3 Downlod</h2> </a>  <CopyToClipboardButton textToCopy={'linkToCopy'} /> </div> */}

        </div>
            

        </>
    )
}